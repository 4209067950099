/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { Column, usePagination, useSortBy, useTable } from 'react-table';

import {
  CXButton,
  CommonContainer,
  FormMultiSelect,
  FormTextBox,
  Pagination,
  SortingIcon,
  SpinningLoader,
  Svg,
  UserGroupLabel,
  notify,
} from 'components';
import { mq, useIsMounted, useIsTablet } from 'helpers';
import { postEditUserRole, postUserRoleSearchRequest } from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import {
  LOADING,
  SORT_BY_CONFIG,
  SORT_ORDER,
  TABLE_SIZE,
  USER_GROUP,
  USER_ROLE,
  USER_ROLE_HIGHLIGHT_DURATION,
} from 'helpers/constants';
import {
  IUserRoleSearchResult,
  IUserRoleSearchResultData,
} from 'models/SearchData';
import { MobileResultItem, UserRoleModal } from 'screens/UserRole/components';

interface IUserRoleSearchForm {
  keywords: string;
  userGroups: string[];
  userRoles: string[];
}

const NoResultText = (): JSX.Element => {
  return <div css={{ fontSize: '0.875rem' }}>No matching results found</div>;
};

const UserRole = (): JSX.Element => {
  const [searchResult, setSearchResult] = useState<IUserRoleSearchResult>({
    data: [],
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [highlightUser, setHighlightUser] = useState<string>();
  const [editUser, setEditUser] = useState<IUserRoleSearchResultData>();
  const [mobilePageIndex, setMobilePageIndex] = useState(0);

  const myTableRef = useRef<HTMLTableSectionElement>(null);
  const myMobileRef = useRef<HTMLTableSectionElement>(null);

  const { data, total } = searchResult;

  const isMounted = useIsMounted();
  const isTablet = useIsTablet();

  const columns = useMemo<Column<IUserRoleSearchResultData>[]>(
    () => [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'galacxyId',
        Header: 'GalaCXy ID',
      },
      {
        accessor: 'group',
        Header: 'User Group',
        Cell: ({ row }) => {
          return (
            <div>
              <UserGroupLabel userGroup={row.original.group} />
            </div>
          );
        },
      },
      {
        accessor: 'roles',
        Header: 'Roles',
        Cell: ({ row }) => {
          return (
            <>
              {row.original.roles ? (
                row.original.roles.map((role) => (
                  <span
                    css={{
                      padding: '0.25rem',
                      borderRadius: '0.25rem',
                      background: COLORS.blue2,
                      fontSize: '0.75rem',
                      color: COLORS.blue6,
                      fontWeight: '600',
                      marginRight: '0.625rem',
                    }}
                    key={role}
                  >
                    {getUserRoleGridDisplay(role)}
                  </span>
                ))
              ) : (
                <span>--</span>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
      {
        id: 'actions',
        accessor: 'galacxyId',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) => (
          <span
            role="presentation"
            css={{ cursor: 'pointer' }}
            onClick={() => handleUpdateClick(row.original)}
          >
            <Svg icon="edit" />
          </span>
        ),
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
    prepareRow,
  } = useTable<IUserRoleSearchResultData>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: Math.ceil(total / TABLE_SIZE),
    },
    useSortBy,
    usePagination,
  );

  const onSubmit: SubmitHandler<IUserRoleSearchForm> = () => {
    setIsLoading(true);
    if (pageIndex > 0) {
      gotoPage(0);
    } else {
      void getPageData();
    }
  };

  const { handleSubmit, register, getValues, setValue, control } =
    useForm<IUserRoleSearchForm>({
      defaultValues: {
        keywords: '',
        userGroups: [],
        userRoles: [],
      },
    });

  const getPageData = useCallback(
    async (onInit?: boolean) => {
      setIsLoading(true);
      const resp: IUserRoleSearchResult = await postUserRoleSearchRequest({
        ...getValues(),
        pageNumber: isTablet ? mobilePageIndex + 1 : pageIndex + 1,
        sortBy: sortBy.length ? sortBy[0].id : SORT_BY_CONFIG.GALACXY_ID,
        sortOrder:
          sortBy.length && sortBy[0].desc ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      });

      if (isMounted()) {
        if (resp) {
          setSearchResult(resp);
          !onInit && notify({ message: 'Search successful', type: 'success' });
        } else {
          notify({
            message: `Cannot find any users`,
            type: 'error',
          });
        }

        setIsLoading(false);
      }
    },
    [getValues().keywords, pageIndex, mobilePageIndex, sortBy],
  );

  const userRoleKeys = Object.keys(USER_ROLE);

  const submitUserRole = async ({
    userRoles,
    userGroup,
    newUser,
  }: {
    userRoles: string[];
    userGroup: string;
    newUser?: string;
  }) => {
    if (editUser || newUser) {
      let galaCXyID;

      if (newUser) {
        galaCXyID = newUser;
      } else if (editUser) {
        galaCXyID = editUser.galacxyId;
      } else {
        return;
      }

      setIsLoading(true);

      const resp = await postEditUserRole({
        galacxyId: galaCXyID,
        userRoles,
        userGroup,
      });

      if (resp.result) {
        handleHighlightUser(galaCXyID);

        const msg = isAddUser
          ? 'New user added successfully'
          : 'User successfully updated';

        notify({ message: msg, type: 'success' });

        void getPageData(true);
      } else {
        setIsLoading(false);

        notify({
          message: `Cannot ${isAddUser ? 'add new' : 'update'} user. ${
            (resp.errMsg as string) || ''
          }`,
          type: 'error',
        });
      }
    }
  };

  const handleAddClick = () => {
    setIsAddUser(true);
    setIsUserModalOpen(true);
  };

  const handleUpdateClick = (editUser: IUserRoleSearchResultData) => {
    setEditUser(editUser);
    setIsUserModalOpen(true);
  };

  const handleHighlightUser = (galacxyId: string) => {
    setHighlightUser(galacxyId);
    setTimeout(() => {
      setHighlightUser(undefined);
    }, USER_ROLE_HIGHLIGHT_DURATION);
  };

  const closeModal = (isOpen: boolean) => {
    setIsUserModalOpen(isOpen);
    setIsAddUser(false);
    setEditUser(undefined);
  };

  const getUserRoleGridDisplay = (name: string): string => {
    const ROLE = userRoleKeys.find((key) => USER_ROLE[key].name === name);
    return ROLE ? USER_ROLE[ROLE].gridDisplay : '';
  };

  useEffect(() => {
    void getPageData(true);
  }, [pageIndex, mobilePageIndex, sortBy]);

  return (
    <CommonContainer>
      <div
        css={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '1rem',
          flex: 1,
        }}
      >
        <section
          css={{
            width: '100%',
            marginBottom: '2rem',
          }}
        >
          <NavLink to="/">
            <span
              css={{
                fontSize: '0.875rem',
                color: COLORS.blue5,
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Home
            </span>
          </NavLink>

          <h1
            css={{
              fontSize: '2rem',
              fontWeight: 700,
              color: COLORS.grey3,
              marginTop: '0.825rem',
            }}
          >
            All Users
          </h1>
          <div
            css={{
              display: 'flex',
              [mq[1]]: {
                flexDirection: 'column',
              },
            }}
          >
            <form
              css={{
                width: '100%',
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                css={{
                  display: 'flex',
                  [mq[1]]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <div
                  css={{
                    position: 'relative',
                    flex: 2,
                  }}
                >
                  <FormTextBox
                    label="Search Name and GalaCXy ID"
                    dataField="keywords"
                    register={register}
                    isClearInput={true}
                    setValue={setValue}
                  />
                </div>
                <div
                  css={{
                    flex: 2,
                    display: 'flex',
                    marginLeft: '0.5rem',
                    [mq[1]]: {
                      marginTop: '0.5rem',
                      marginLeft: 0,
                    },
                  }}
                >
                  <div
                    css={{
                      position: 'relative',
                      flex: 1,
                    }}
                  >
                    <FormMultiSelect
                      label="User Group"
                      dataField="userGroups"
                      control={control}
                      dataArr={Object.keys(USER_GROUP).map((key) => ({
                        name: USER_GROUP[key].display,
                        value: USER_GROUP[key].value,
                      }))}
                    />
                  </div>
                  <div
                    css={{
                      position: 'relative',
                      flex: 1,
                      marginLeft: '0.5rem',
                    }}
                  >
                    <FormMultiSelect
                      label="Roles"
                      dataField="userRoles"
                      control={control}
                      dataArr={userRoleKeys
                        .filter((key) =>
                          USER_ROLE[key].isShowInRoleAssignment !== undefined
                            ? USER_ROLE[key].isShowInRoleAssignment
                            : true,
                        )
                        .map((key) => ({
                          name: USER_ROLE[key].gridDisplay,
                          value: USER_ROLE[key].name,
                        }))}
                    />
                  </div>
                </div>
                <div>
                  <CXButton
                    customStyles={{
                      marginRight: '2rem',
                      marginLeft: '0.4rem',
                      [mq[1]]: {
                        marginRight: 0,
                        marginLeft: 0,
                        marginTop: '1rem',
                        width: '100%',
                      },
                    }}
                    type="submit"
                    variant="primary-outline"
                  >
                    Search
                  </CXButton>
                </div>
              </div>
            </form>

            <div
              css={{
                [mq[1]]: {
                  marginTop: '1rem',
                },
              }}
            >
              <CXButton
                customStyles={{
                  minWidth: '11.625rem',
                  [mq[1]]: {
                    width: '100%',
                  },
                }}
                variant="primary"
                onClick={handleAddClick}
              >
                Add New User
              </CXButton>
            </div>
          </div>
        </section>
        {isLoading ? (
          <SpinningLoader isNewLoadingIcon={true} loaderText={LOADING} />
        ) : (
          <>
            <section
              ref={myTableRef}
              css={{
                [mq[1]]: {
                  display: 'none',
                },
              }}
            >
              <div
                css={{
                  fontSize: '1.125rem',
                  color: COLORS.grey3,
                  fontWeight: 600,
                }}
              >
                All Users ({total})
              </div>
              <div>
                {data.length > 0 ? (
                  <table
                    {...getTableProps()}
                    css={{
                      width: '100%',
                    }}
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          css={{ height: '2.5rem' }}
                        >
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}
                              css={{
                                fontSize: '0.75rem',
                                lineHeight: '1.1875rem',
                                paddingLeft: '0.5rem',
                              }}
                            >
                              {column.render('Header')}
                              <span>
                                {column.getHeaderProps().key !==
                                  'header_roles' &&
                                column.getHeaderProps().key !==
                                  'header_actions' ? (
                                  <SortingIcon
                                    isSorted={column.isSorted}
                                    isSortedDesc={column.isSortedDesc}
                                  />
                                ) : (
                                  ''
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            css={{
                              height: '3rem',
                              ...(row.original.galacxyId === highlightUser && {
                                background: COLORS.white2,
                              }),
                            }}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  css={{
                                    textAlign: 'left',
                                    borderBottom: `1px solid ${COLORS.grey7}`,
                                    fontWeight: 700,
                                    fontSize: '0.875rem',
                                    lineHeight: '1.28rem',
                                    overflowWrap: 'break-word',
                                    padding: '0.5rem',
                                    position: 'relative',
                                  }}
                                >
                                  <span
                                    css={{
                                      display: 'block',
                                      width: '100%',
                                      height: '100%',
                                      ':hover': {
                                        textDecoration: 'none',
                                      },
                                    }}
                                  >
                                    {cell.render('Cell')}
                                  </span>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <NoResultText />
                )}
              </div>
            </section>
            <section
              ref={myMobileRef}
              css={{
                flex: 1,
                display: 'none',
                [mq[1]]: {
                  display: 'flex',
                  flexWrap: 'wrap',
                },
              }}
            >
              <div
                css={{
                  marginBottom: '0.5rem',
                  fontFamily: 'CathaySans Medium',
                  fontSize: '1.125rem',
                  color: COLORS.black,
                }}
              >
                All Users ({total})
              </div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  paddingBottom: '3rem',
                }}
              >
                {data.length > 0 ? (
                  <>
                    {data.map((resultItem, index) => (
                      <MobileResultItem
                        key={index}
                        item={resultItem}
                        handleUpdateClick={handleUpdateClick}
                        getUserRoleGridDisplay={getUserRoleGridDisplay}
                      />
                    ))}
                    {/* Mobile Pagination */}
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        [mq[2]]: {
                          flexDirection: 'column-reverse',
                        },
                      }}
                    >
                      <Pagination
                        gotoPage={(idx) => setMobilePageIndex(idx)}
                        nextPage={() => setMobilePageIndex(mobilePageIndex + 1)}
                        previousPage={() =>
                          setMobilePageIndex(mobilePageIndex - 1)
                        }
                        pageIndex={mobilePageIndex}
                        noOfPages={Math.ceil(total / TABLE_SIZE)}
                        scrollToView={() =>
                          myMobileRef?.current?.scrollIntoView()
                        }
                      />
                    </div>
                  </>
                ) : (
                  <NoResultText />
                )}
              </div>
            </section>
            {!!data.length && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  [mq[1]]: { display: 'none' },
                }}
              >
                <Pagination
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageIndex={pageIndex}
                  noOfPages={Math.ceil(total / TABLE_SIZE)}
                  scrollToView={() => myTableRef?.current?.scrollIntoView()}
                />
              </div>
            )}
          </>
        )}

        <UserRoleModal
          isAddUser={isAddUser}
          isOpen={isUserModalOpen}
          setIsOpen={closeModal}
          submitUserRole={submitUserRole}
          editUser={editUser}
        />
      </div>
    </CommonContainer>
  );
};

export default UserRole;
