/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { get } from 'lodash';
import { Path, PathValue } from 'react-hook-form';

import { ErrorMessageWrapper, TextBox } from 'components';
import { ERROR_MSGS, PATTERNS } from 'helpers/constants';

import { TextBoxProps } from '../../@types/formControl';

const FormTextBox = <T,>({
  label = '',
  dataField,
  rules,
  register,
  errors,
  defaultValue,
  disabled,
  isClearInput,
  setValue,
}: TextBoxProps<T>): JSX.Element => {
  // for security assessment, check for special characters in free text input
  const newRules =
    !rules || (rules && !rules.pattern)
      ? {
          ...rules,
          pattern: {
            value: PATTERNS.SPECIAL_CHAR,
            message: ERROR_MSGS.NO_SPECIAL_CHAR,
          },
        }
      : rules;

  const hasError = !!get(errors, dataField);

  return (
    <div css={{ flex: 1 }}>
      <TextBox
        label={label}
        disabled={disabled}
        hasError={hasError}
        {...register(dataField, {
          ...newRules,
          value: (defaultValue || '') as PathValue<T, Path<T>>,
        })}
        isClearInput={isClearInput}
        onReset={(value: string) => {
          const resetVal = value as PathValue<T, Path<T>>;
          setValue && setValue(dataField, resetVal);
        }}
      />
      {errors && <ErrorMessageWrapper errors={errors} dataField={dataField} />}
    </div>
  );
};

export default FormTextBox;
